import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import _ from 'lodash';
import { useHistory } from 'react-router';

import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';
import { /* LINK_TYPE, */ SIZE_TYPE } from 'client/types';
import { COMPANY_PAGE_ROUTE, MANDATE_PAGE_ROUTE } from 'client/constants/routes';
// import LinkButton from '../LinkButton';
import { imgixUrl } from 'client/utils/imgix-url';
import RatingUpButton from '../RatingUpButton';

import { useAppSelector, useAppDispatch } from 'client/hooks';

import { selectAuth, selectUserRole } from 'client/redux/user/selectors';

import css from './DoubleImageSlide.module.scss';
import PlayIcon from '../PlayIcon';

type Props = {
  className?: string;
  isUpvoteDisabled?: boolean;
  size?: SIZE_TYPE;
  buttonSize?: SIZE_TYPE;
  item: MainPageWebsiteSliderItem;
  isMandateLink?: boolean;
  buttonLabel: string;
};

const DoubleImageSlide: React.FC<Props> = ({
  item,
  isUpvoteDisabled = false,
  size = SIZE_TYPE.SM,
  buttonSize = SIZE_TYPE.SM,
  className = '',
  isMandateLink = false,
  buttonLabel = '',
}) => {
  return (
    <div className={cn(css.doubleImageSlide, css[size], className)}>
      {isMandateLink && <div className={css.investmentStatus}>{item.mandateInfo?.investmentStatusTitle}</div>}
      <Link
        to={
          isMandateLink
            ? MANDATE_PAGE_ROUTE.replace(':id', item.mandateInfo?.id || '')
            : COMPANY_PAGE_ROUTE.replace(':id', item._id)
        }
        className={cn(css.slideInner, isUpvoteDisabled && css.isUpvoteDisabled)}
      >
        <img
          src={imgixUrl(item.decorImage || item.mainImage, { auto: 'compress' })}
          alt={item.name}
          className={css.mainImage}
        />
        {/* {!isUpvoteDisabled && (
          <div className={css.upvote}>
            <span>{item.rating}</span>
            <RatingUpButton size="small" id={item._id} onClick={() => console.info('Upvote Clicked')} />
          </div>
        )} */}
        <div className={css.bottom}>
          <h6>{item.name}</h6>
          <p>{item.subTitle}</p>
          <span className={css.button}>
            <PlayIcon size={size === SIZE_TYPE.L ? SIZE_TYPE.M : size} className={css.iconPlay} />
            <span>{buttonLabel}</span>
          </span>
        </div>
      </Link>
    </div>
  );
};

export default DoubleImageSlide;

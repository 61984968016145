import * as yup from 'yup';

import { Onboarding, Version } from 'models/user/onboarding';
import { filterDependencies, configureStep, getStageCurrentStepName, getStageStep } from '../stage';
import { InputType, constants } from '../input-types';

/**
 * Returns accreditation questionnaire form fields
 */
export function getQuestionnaire(data: Onboarding) {
  const t = (path: string) => `questionnaire.accreditationQuestionnaire.${path}`;
  const allSteps = [
    configureStep(
      {
        type: InputType.SELECT,
        name: 'occupation',
        label: t('questionnaire.occupation.label'),
        options: [
          { value: 'a', label: t('questionnaire.occupation.options.a') },
          { value: 'b', label: t('questionnaire.occupation.options.b') },
          { value: 'c', label: t('questionnaire.occupation.options.c') },
          { value: 'd', label: t('questionnaire.occupation.options.d') },
          { value: 'e', label: t('questionnaire.occupation.options.e') }, // "Other"
        ],
        withOther: true,
        schema: yup.array().length(1).of(yup.string()).required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        multiple: true,
        name: 'appliesTo',
        label: t('questionnaire.appliesTo.label'),
        explanation: t('questionnaire.appliesTo.explanation'),
        options: [
          { value: 'a', label: t('questionnaire.appliesTo.options.a') },
          { value: 'b', label: t('questionnaire.appliesTo.options.b') },
          { value: 'c', label: t('questionnaire.appliesTo.options.c') },
          { value: 'd', label: t('questionnaire.appliesTo.options.d') },
        ],
        schema: yup
          .array()
          .min(1)
          .max(4)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        multiple: true,
        name: 'experience',
        label: t('questionnaire.experience.label'),
        options: [
          { value: 'a', label: t('questionnaire.experience.options.a') },
          { value: 'b', label: t('questionnaire.experience.options.b') },
          { value: 'c', label: t('questionnaire.experience.options.c') },
          { value: 'd', label: t('questionnaire.experience.options.d') },
          { value: 'e', label: t('questionnaire.experience.options.e') },
          { value: 'f', label: t('questionnaire.experience.options.f') },
          { value: 'g', label: t('questionnaire.experience.options.g') },
        ],
        schema: yup
          .array()
          .min(1)
          .max(7)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd', 'e', 'f', 'g']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.TEXT_AREA,
        name: 'experienceExplanation',
        label: t('questionnaire.experienceExplanation.label'),
        explanation: t('questionnaire.experienceExplanation.explanation'),
        schema: yup.string().min(constants.TEXT_AREA_MIN_LENGTH).max(constants.TEXT_AREA_MAX_LENGTH).required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.CHECKBOX,
        name: 'financeEducation',
        label: t('questionnaire.financeEducation.label'),
        schema: yup.boolean().required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.TEXT_AREA,
        name: 'privateEquity',
        label: t('questionnaire.privateEquity.label'),
        schema: yup.string().min(constants.TEXT_AREA_MIN_LENGTH).max(constants.TEXT_AREA_MAX_LENGTH).required(),
        dependsOn: {
          field: 'financeEducation',
          value: true,
        },
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        name: 'objectiveDescription',
        label: t('questionnaire.objectiveDescription.label'),
        options: [
          { value: 'a', label: t('questionnaire.objectiveDescription.options.a') },
          { value: 'b', label: t('questionnaire.objectiveDescription.options.b') },
          { value: 'c', label: t('questionnaire.objectiveDescription.options.c') },
          { value: 'd', label: t('questionnaire.objectiveDescription.options.d') },
        ],
        schema: yup
          .array()
          .length(1)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.CHECKBOX,
        name: 'totalLossAcceptance',
        label: t('questionnaire.totalLossAcceptance.label'),
        schema: yup.boolean().required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        name: 'investmentPortionOfTotal',
        label: t('questionnaire.investmentPortionOfTotal.label'),
        options: [
          { value: 'a', label: t('questionnaire.investmentPortionOfTotal.options.a') },
          { value: 'b', label: t('questionnaire.investmentPortionOfTotal.options.b') },
          { value: 'c', label: t('questionnaire.investmentPortionOfTotal.options.c') },
          { value: 'd', label: t('questionnaire.investmentPortionOfTotal.options.d') },
          { value: 'e', label: t('questionnaire.investmentPortionOfTotal.options.e') },
        ],
        schema: yup
          .array()
          .length(1)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd', 'e']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        name: 'liquidAssetsDuration',
        label: t('questionnaire.liquidAssetsDuration.label'),
        options: [
          { value: 'a', label: t('questionnaire.liquidAssetsDuration.options.a') },
          { value: 'b', label: t('questionnaire.liquidAssetsDuration.options.b') },
          { value: 'c', label: t('questionnaire.liquidAssetsDuration.options.c') },
          { value: 'd', label: t('questionnaire.liquidAssetsDuration.options.d') },
          { value: 'e', label: t('questionnaire.liquidAssetsDuration.options.e') },
        ],
        schema: yup
          .array()
          .length(1)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd', 'e']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        name: 'timeHorizon',
        label: t('questionnaire.timeHorizon.label'),
        explanation: t('questionnaire.timeHorizon.explanation'),
        options: [
          { value: 'a', label: t('questionnaire.timeHorizon.options.a') },
          { value: 'b', label: t('questionnaire.timeHorizon.options.b') },
        ],
        schema: yup
          .array()
          .length(1)
          .of(yup.string().oneOf(['a', 'b']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.CHECKBOX,
        name: 'longTermInvestmentLiquidityNeeds',
        label: t('questionnaire.longTermInvestmentLiquidityNeeds.label'),
        explanation: t('questionnaire.longTermInvestmentLiquidityNeeds.explanation'),
        schema: yup.boolean().required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        name: 'investingAs',
        label: t('questionnaire.investingAs.label'),
        options: [
          { value: 'a', label: t('questionnaire.investingAs.options.a') },
          { value: 'b', label: t('questionnaire.investingAs.options.b') },
          { value: 'c', label: t('questionnaire.investingAs.options.c') },
          { value: 'd', label: t('questionnaire.investingAs.options.d') },
          { value: 'e', label: t('questionnaire.investingAs.options.e') },
        ],
        schema: yup
          .array()
          .length(1)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd', 'e']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        name: 'sourceOfFunds',
        label: t('questionnaire.sourceOfFunds.label'),
        multiple: true,
        options: [
          { value: 'a', label: t('questionnaire.sourceOfFunds.options.a') },
          { value: 'b', label: t('questionnaire.sourceOfFunds.options.b') },
          { value: 'c', label: t('questionnaire.sourceOfFunds.options.c') },
          { value: 'd', label: t('questionnaire.sourceOfFunds.options.d') },
          { value: 'e', label: t('questionnaire.sourceOfFunds.options.e') },
          { value: 'f', label: t('questionnaire.sourceOfFunds.options.f') },
          { value: 'g', label: t('questionnaire.sourceOfFunds.options.g') },
          { value: 'h', label: t('questionnaire.sourceOfFunds.options.h') }, // "Other"
        ],
        schema: yup
          .array()
          .min(1)
          .max(8)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']))
          .required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.TEXT_AREA,
        name: 'sourceOfSavings',
        label: t('questionnaire.sourceOfSavings.label'),
        schema: yup.string().min(constants.TEXT_AREA_MIN_LENGTH).max(constants.TEXT_AREA_MAX_LENGTH).required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.CHECKBOX,
        name: 'prominentPublicRole',
        label: t('questionnaire.prominentPublicRole.label'),
        explanation: t('questionnaire.prominentPublicRole.explanation'),
        schema: yup.boolean().required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.CHECKBOX,
        name: 'prominentPublicRoleRelation',
        label: t('questionnaire.prominentPublicRoleRelation.label'),
        explanation: t('questionnaire.prominentPublicRoleRelation.explanation'),
        schema: yup.boolean().required(),
      },
      data
    ),
    configureStep(
      {
        type: InputType.SELECT,
        name: 'USRelation',
        label: t('questionnaire.USRelation.label'),
        options: [
          { value: 'a', label: t('questionnaire.USRelation.options.a') },
          { value: 'b', label: t('questionnaire.USRelation.options.b') },
          { value: 'c', label: t('questionnaire.USRelation.options.c') },
          { value: 'd', label: t('questionnaire.USRelation.options.d') },
        ],
        schema: yup
          .array()
          .length(1)
          .of(yup.string().oneOf(['a', 'b', 'c', 'd']))
          .required(),
      },
      data
    ),
  ];

  if (data.version !== Version.FULL) {
    allSteps.splice(6, 6);
  }

  return {
    steps: filterDependencies(allSteps, data),
  };
}

export function getCurrentStep(data: Onboarding) {
  const currentStepName = getStageCurrentStepName(getQuestionnaire(data).steps);
  const step = currentStepName ? getStep(data, currentStepName) : null;
  console.info('step', step);
  return step;
}

export function getStep(data: Onboarding, targetStep: string) {
  return getStageStep(getQuestionnaire(data).steps, targetStep);
}

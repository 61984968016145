import React from 'react';
import cn from 'classnames';

import { useAppDispatch, useTranslation } from 'client/hooks';
import { Button } from 'client/components/common/mobile';

import css from './Hero.module.scss';
import { setPopup } from 'client/redux/ui';
import { selectAuth } from 'client/redux/user/selectors';
import { useAppSelector } from 'client/hooks/redux';

interface Props {
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const Hero: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const auth = useAppSelector(selectAuth);
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  const onJoinClick = () => {
    dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-up' } }));
  };

  const onLoginBtnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
  };

  return (
    <div className={cn(css.hero, className)} ref={props.containerRef}>
      <h1 className={css.heading} dangerouslySetInnerHTML={{ __html: translate('mainPage.heading') }} />
      <p className={css.subHeading} dangerouslySetInnerHTML={{ __html: translate('mainPage.subHeadingMobile') }} />
      {auth.isLoggedIn === false && (
        <>
          <Button className={css.joinBtn} onClick={onJoinClick} size="large" variant="primary">
            <span>{translate('mainPage.joinBtnLabel')}</span>
          </Button>

          <a href="#" className={css.loginBtn} onClick={onLoginBtnClick}>
            {translate('mainPage.loginBtnLabel')}
          </a>
        </>
      )}
    </div>
  );
};

export default Hero;

import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { useAppSelector, useTranslation, useAppDispatch, useOnClickOutside } from 'client/hooks';
import { setMenuState, setPopup } from 'client/redux/ui';
import { selectMenuState } from 'client/redux/ui/selectors';
import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import { selectAuth } from 'client/redux/user/selectors';
import { State as AuthState } from 'client/components/common/Login/state';

import cicLogo from 'client/assets/header/cic-logo-v2.svg';
import cicLogoPNG from 'client/assets/header/cic-logo-v2.png';

import Button from '../Button';
import Menu from '../Menu';
import User from '../User';
import Search from '../Search';

import css from './Header.module.scss';

const Header = () => {
  const { translate } = useTranslation();
  const isActiveMenu = useAppSelector(selectMenuState) !== 'hidden';
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const clickOnMenuButton = () => {
    if (isActiveMenu) {
      dispatch(setMenuState('hidden'));
    } else {
      dispatch(setMenuState('navigation'));
    }
  };

  const onLoginClick = (type: AuthState['authType']) => {
    dispatch(setPopup({ type: 'auth', data: { initialAuthType: type } }));
  };

  const handleClickOutside = () => {
    dispatch(setMenuState('hidden'));
  };

  useOnClickOutside<HTMLDivElement>(menuRef, handleClickOutside);

  return (
    <header>
      <div className={cn(css.headerWrapper)}>
        <div className={css.left}>
          <div className={css.menuWrapper} ref={menuRef}>
            <div className={cn(css.menuButton, isActiveMenu && css.active)} onClick={clickOnMenuButton}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <CSSTransition
              timeout={parseInt(css.opentransitiontransform, 10)}
              in={isActiveMenu}
              classNames={{
                enter: css.enter,
                enterActive: css.enterActive,
                exit: css.exit,
                exitActive: css.exitActive,
              }}
              unmountOnExit
            >
              <Menu />
            </CSSTransition>
          </div>
          <div className={css.logoWrapper}>
            <Link to={MAIN_PAGE_ROUTE} className={css.logo}>
              <img src={cicLogo ?? cicLogoPNG} alt="Catalyst Investors' Club" />
            </Link>
          </div>
        </div>
        <div className={css.right}>
          <Search />
          {auth.isLoggedIn ? (
            <User />
          ) : (
            <>
              <div className={css.signInButton} onClick={() => onLoginClick('sign-in')}>
                {translate('header.signInBtn')}
              </div>
              <Button className={css.signUpBtn} size="medium" onClick={() => onLoginClick('sign-up')}>
                {translate('header.signUpBtn')}
              </Button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
